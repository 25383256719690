import { TextValueItem } from "@/models/TextValueItem";
import api from "@/services/api";
import { Component, Prop, Watch } from "vue-property-decorator";
import { CommercialeCommissioneSimula } from "@/models/commerciali/commercialeCommissioneSimula";
import { CommissioniSimulaResult } from "../../../models/commerciali/commissioniSimulaResult";
import gridComponentBase from "@/components/grid/gridComponentBase.vue";
import { CommercialeCommissioneAnalisi } from "@/models/commerciali/commercialeCommissioneAnalisi";
import { process } from "@progress/kendo-data-query";
import { CommercialeCommissioneAnalisiResult } from "@/models/commerciali/commercialeCommissioneAnalisiResult";
import { CommercialeCommissioneAnalisiTotali } from "@/models/commerciali/commercialeCommissioneAnalisiTotali";
import moment from "moment";
import permessi from "@/config/permessi";

const _ENDPONT = api.endpoint.ANALISI_COMMISSIONI.ANALISI;
const _ENDPONT_TOTALI = api.endpoint.ANALISI_COMMISSIONI.ANALISI_TOTALI;

@Component({})
export default class analisiCommissioniPage extends gridComponentBase {
	get PERMESSI(): string[] { return [permessi.BK.Commerciali.ANALISICOMMISSIONI]; }
	commerciali: TextValueItem[] = [];
	commercialeSelected: TextValueItem = null;

	dataDa: Date | null = null;
	dataA: Date | null = null;
	datePickerFooter = "Oggi - #=kendo.toString(data, 'dd/MM/yyyy') #";

	result: CommissioniSimulaResult = null;
	totali: CommercialeCommissioneAnalisiTotali = null;

	itemsGrid: CommercialeCommissioneAnalisiResult[] = [];
	kendop: any = kendo;

	aggregates: any[] = [
		{ field: "commissioniCommerciale", aggregate: "sum" },
		{ field: "commissioniDistributore", aggregate: "sum" },
		{ field: "importoViaggio", aggregate: "sum" },
		{ field: "premio", aggregate: "sum" },
		{ field: "premioNetto", aggregate: "sum" }
	];

	get columns(): Array<any> {
		return [
			{ field: "itemID", title: "ID", width: 20, filterable: false, hidden: true, cell: "defaultCellTemplate", headerCell: "myHeaderCellTemplate", editHide: true },

			{ field: "numeroAdesione", title: "Numero Adesione", width: 150, headerCell: "myHeaderCellTemplate", filterable: true, export: true, filterType: "numeric-int", type: "numeric-int", format: "{0:#}" },
			//{ field: "numeroAdesioneStampato", title: "Numero Adesione Stampato", width: 150, headerCell: "myHeaderCellTemplate", filterable: true, export: true },
			{ field: "distributore", title: "Distributore", width: 200, headerCell: "myHeaderCellTemplate", filterable: true, export: true },
			{ field: "destinazione", title: this.$i18n.t("generico.destinazione").toString(), width: 150, headerCell: "myHeaderCellTemplate", filterable: true, export: true },
			{ field: "pacchetto", title: "Prodotto", width: 150, headerCell: "myHeaderCellTemplate", filterable: true, export: true },
			{ field: "prodotto", title: "Polizza", width: 150, headerCell: "myHeaderCellTemplate", filterable: true, export: true },
			{
				field: "commissioniBT",
				title: "Commissioni B&T",
				width: 150,
				format: "{0:n2}",
				aggregates: ["sum"],
				footerTemplate: "Tot: #=kendo.toString(sum,'n2')#",
				groupFooterTemplate: "Tot: #=kendo.toString(sum,'n2')#",
				headerCell: "myHeaderCellTemplate",
				footerCell: "myFooterCommissioniBTCellTemplate",
				filterable: true,
				export: true,
				filterType: "numeric",
				type: "numeric"
			},
			{
				field: "commissioniCommerciale",
				title: "Commissioni Commerciale",
				width: 150,
				format: "{0:n2}",
				aggregates: ["sum"],
				footerTemplate: "Tot: #=kendo.toString(sum,'n2')#",
				groupFooterTemplate: "Tot: #=kendo.toString(sum,'n2')#",
				headerCell: "myHeaderCellTemplate",
				footerCell: "myFooterCommissioniCommercialiCellTemplate",
				filterable: true,
				export: true,
				filterType: "numeric",
				type: "numeric"
			},

			{
				field: "commissioniDistributore",
				title: "Commissioni Distributore",
				width: 150,
				format: "{0:n2}",
				aggregates: ["sum"],
				footerTemplate: "Tot: #=kendo.toString(sum,'n2')#",
				groupFooterTemplate: "Tot: #=kendo.toString(sum,'n2')#",
				headerCell: "myHeaderCellTemplate",
				footerCell: "myFooterCommissioniDistributoriCellTemplate",
				filterable: true,
				export: true,
				filterType: "numeric",
				type: "numeric"
			},

			{
				field: "importoViaggio",
				title: "Importo Viaggio",
				width: 150,
				aggregates: ["sum"],
				footerTemplate: "Tot: #=kendo.toString(sum,'n2')#",
				groupFooterTemplate: "Tot: #=kendo.toString(sum,'n2')#",
				headerCell: "myHeaderCellTemplate",
				footerCell: "myFooterImportoViaggioCellTemplate",
				filterable: true,
				export: true,
				filterType: "numeric",
				type: "numeric"
			},
			{ field: "numeroPartecipanti", title: "Num Partecipanti", width: 150, headerCell: "myHeaderCellTemplate", filterable: true, filterType: "numeric-int", type: "numeric-int", export: true },
			{
				field: "premio",
				title: this.$i18n.t("generico.premio").toString(),
				width: 150,
				aggregates: ["sum"],
				footerTemplate: "Tot: #=kendo.toString(sum,'n2')#",
				groupFooterTemplate: "Tot: #=kendo.toString(sum,'n2')#",
				headerCell: "myHeaderCellTemplate",
				footerCell: "myFooterPremioCellTemplate",
				filterable: true,
				export: true,
				filterType: "numeric",
				type: "numeric"
			},
			{
				field: "premioNetto",
				title: this.$i18n.t("generico.premioNetto").toString(),
				width: 150,
				aggregates: ["sum"],
				footerTemplate: "Tot: #=kendo.toString(sum,'n2')#",
				groupFooterTemplate: "Tot: #=kendo.toString(sum,'n2')#",
				headerCell: "myHeaderCellTemplate",
				footerCell: "myFooterPremioNettoCellTemplate",
				filterable: true,
				export: true,
				filterType: "numeric",
				type: "numeric"
			},
			{
				field: "istanteInserimento",
				title: "Istante Inserimento",
				width: 150,
				type: "date",
				headerType: "daterange",
				format: "{0:dd/MM/yyyy}",
				headerCell: "myHeaderCellTemplate",
				filterable: true,
				export: true,
				exportRenderFunction: this.exportRenderDate
			},
			{ field: "inizio", title: this.$i18n.t("generico.inizio").toString(), width: 150, format: "{0:dd/MM/yyyy}", type: "date", headerType: "daterange", headerCell: "myHeaderCellTemplate", filterable: true, export: true, exportRenderFunction: this.exportRenderDate },
			{ field: "fine", title: this.$i18n.t("generico.fine").toString(), width: 150, format: "{0:dd/MM/yyyy}", type: "date", headerType: "daterange", headerCell: "myHeaderCellTemplate", filterable: true, export: true, exportRenderFunction: this.exportRenderDate }
		];
	}

	created() {
		this.take = 100;
		this.pageable.pageSize = 100;
		this.pageSize = 100;

		if (this.group) {
			this.group.map(group => (group.aggregates = this.aggregates));
		}
		this.group = this.group;
	}

	mounted() {
		this.getCommercialiDropDown();
	}

	getCommercialiDropDown() {
		var self = this;
		return api
			.getDatiDropDown(api.endpoint.DROPDOWN.COMMERCIALI)
			.then(res => {
				self.commerciali = res;
			})
			.catch(err => {
				console.log(err);
			});
	}

	onExecuteAction() {
		if (this.commercialeSelected && this.dataDa && this.dataA) {
			this.getData();
		} else {
			this.$bvToast.toast(this.$i18n.t("analisicommissioni.filtroGriglia").toString(), {
				variant: "warning",
				title: this.$i18n.t("generico.warning").toString(),
				solid: true
			});
		}
	}

	getData() {
		//var self = this;
		// let data: CommercialeCommissioneAnalisi = {
		// 	commercialeID: this.commercialeSelected.value as number,
		// 	startDate: this.dataDa,
		// 	endDate: this.dataA,
		// 	filter: this.filter,
		// 	sort: this.sort,
		// 	page: this.pageIndex,
		// 	pageSize: this.pageSize,
		// 	group: this.groupForSort
		// };
		if (!this.commercialeSelected || !this.dataDa || !this.dataA) {
			this.$bvToast.toast(this.$i18n.t("analisicommissioni.filtroGriglia").toString(), {
				variant: "warning",
				title: this.$i18n.t("generico.warning").toString(),
				solid: true
			});
			return;
		}
		let commercialeId = this.commercialeSelected.value;
		let startDate = moment(this.dataDa)
			.utc()
			.format();
		let endDate = moment(this.dataA)
			.utc()
			.format();

		api.getTotaliAnalisiCommissioni(`${_ENDPONT_TOTALI}?commercialeID=${commercialeId}&startDate=${startDate}&endDate=${endDate}`)
			.then(res => {
				this.totali = res;
				this.getGridData(`${_ENDPONT}?commercialeID=${commercialeId}&startDate=${startDate}&endDate=${endDate}`);
			})


		// api.getDatiGrigliaAnalisiCommissioni(_ENDPONT, data)
		// 	.then(res => {
		// 		self.itemsGrid = res;
		// 		self.gridData = process(res, { take: self.take, skip: 0, group: self.group });
		// 		self.total = res.length;
		// 	})
		// 	.catch(err => {
		// 		console.log(err);
		// 	});
	}

	getTotale(nomeTot) {
		return this.totali ? this.totali[nomeTot] || 0 : 0;
	}

	exportExcel() {
		// var self = this;
		// let data: CommercialeCommissioneAnalisi = {
		// 	commercialeID: this.commercialeSelected.value as number,
		// 	startDate: this.dataDa,
		// 	endDate: this.dataA,
		// 	filter: this.filter,
		// 	sort: this.sort,
		// 	page: this.pageIndex,
		// 	pageSize: this.pageSize,
		// 	group: this.groupForSort
		// };
		if (!this.commercialeSelected || !this.dataDa || !this.dataA) {
			this.$bvToast.toast(this.$i18n.t("analisicommissioni.filtroGriglia").toString(), {
				variant: "warning",
				title: this.$i18n.t("generico.warning").toString(),
				solid: true
			});
			return;
		}
		let commercialeId = this.commercialeSelected.value;
		let startDate = moment(this.dataDa)
			.utc()
			.format();
		let endDate = moment(this.dataA)
			.utc()
			.format();

		let endpoint = `${_ENDPONT}?commercialeID=${commercialeId}&startDate=${startDate}&endDate=${endDate}`;
		this.exportGridExcel(endpoint);

		// api.getDatiGrigliaAnalisiCommissioni(_ENDPONT, data)
		// 	.then(res => {
		// 		self.exportGridExcel(null, res);
		// 	})
		// 	.catch(err => {
		// 		console.log(err);
		// 	});
	}

	get calcoloResult() {
		if (this.result && this.result.calcolo) {
			return this.result.calcolo;
		}

		return "";
	}

	get getCommissioniBTTotale(): number {
		let result = 0;

		if (this.itemsGrid && this.itemsGrid.length > 0) {
			let values = this.itemsGrid.map(function (item) {
				return item.commissioniBT ? item.commissioniBT : 0;
			});

			result = this._.sum(values);
		}

		return result;
	}

	get getCommissioniCommercialeTotale(): number {
		let result = 0;

		if (this.itemsGrid && this.itemsGrid.length > 0) {
			let values = this.itemsGrid.map(function (item) {
				return item.commissioniCommerciale ? item.commissioniCommerciale : 0;
			});

			result = this._.sum(values);
		}

		return result;
	}

	get getCommissioniDistributoreTotale(): number {
		let result = 0;

		if (this.itemsGrid && this.itemsGrid.length > 0) {
			let values = this.itemsGrid.map(function (item) {
				return item.commissioniDistributore ? item.commissioniDistributore : 0;
			});

			result = this._.sum(values);
		}

		return result;
	}

	get getImportoViaggioTotale(): number {
		let result = 0;

		if (this.itemsGrid && this.itemsGrid.length > 0) {
			let values = this.itemsGrid.map(function (item) {
				return item.importoViaggio ? item.importoViaggio : 0;
			});

			result = this._.sum(values);
		}

		return result;
	}

	get getPremioTotale(): number {
		let result = 0;

		if (this.itemsGrid && this.itemsGrid.length > 0) {
			let values = this.itemsGrid.map(function (item) {
				return item.premio ? item.premio : 0;
			});

			result = this._.sum(values);
		}

		return result;
	}

	get getPremioNettoTotale(): number {
		let result = 0;

		if (this.itemsGrid && this.itemsGrid.length > 0) {
			let values = this.itemsGrid.map(function (item) {
				return item.premioNetto ? item.premioNetto : 0;
			});

			result = this._.sum(values);
		}

		return result;
	}
}
